html,
body {
    min-height: 100dvh;
    min-height: -webkit-fill-available;
}
body {
    margin: 0;
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 2rem;
}

.ml-5 {
    margin-left: 4rem;
}

.ml-6 {
    margin-left: 8rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 2rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-2-imp {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-3p5 {
    margin-top: 1.5rem;
}

.m-0 {
    margin: 0 !important;
}

.form-item-m-0 {
    nz-form-item {
        margin: 0 !important;
    }
}

.p-0 {
    padding: 0 !important;
}

.p-2 {
    padding: 0.5rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.pr-0 {
    padding-right: 0 !important;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-3 {
    gap: 1rem;
}

.gap-4 {
    gap: 2rem;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.float-right {
    float: right;
}

.ant-form-item {
    margin-bottom: 10px;
}

.w-100 {
    width: 100%;
}

.w-full {
    width: 100%;
}

.w-5 {
    width: 5%;
}

.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.mw-10 {
    max-width: 10%;
}

.mw-40 {
    max-width: 40%;
}

.text-3xl {
    font-size: 1.75rem;
}

.d-inline-flex {
    display: inline-flex;
}

.draw-control {
    top: 4rem;
    left: 0.5rem;
}

.zoom-to-fit-control {
    top: 8rem;
    left: 0.5rem;
}

.fit-control {
    top: 10rem;
    left: 0.5rem;
    color: white;
}

.meassure-tooltip-control {
    top: 6rem;
    left: 0.5rem;
    cursor: pointer;

    .tooltip-enabled {
        background-color: green;
        color: #ffffff;
    }
    &.ol-control button.tooltip-enabled:focus {
        background-color: green;
    }
    &.ol-control button.tooltip-enabled:hover {
        background-color: green;
    }

    &.ol-control button:focus {
        background-color: green;
    }
    &.ol-control button:hover {
        background-color: rgba(0, 60, 136, 0.5);
    }
}

.pointer {
    cursor: pointer;
}

.map {
    .tooltip-measure {
        opacity: 1;
        font-weight: bold;
    }

    .tooltip-static {
        background-color: #ffcc33;
        color: black;
        border: 1px solid white;
        opacity: 1;
    }

    .tooltip-measure:before,
    .tooltip-static:before {
        border-top: 6px solid rgba(0, 0, 0, 0.5);
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        content: '';
        position: absolute;
        bottom: -6px;
        margin-left: -7px;
        left: 50%;
    }

    .tooltip-static:before {
        border-top-color: #ffcc33;
    }

    .ol-attribution {
        left: 0.5em;
        right: auto;
    }
}

.hidden {
    display: none;
}

.green-row {
    background-color: rgba(0, 122, 2, 0.4) !important;
    &:hover td {
        background-color: rgba(0, 122, 2, 0.2) !important;
    }
}

.red-row {
    background-color: rgba(122, 0, 0, 0.4) !important;
    &:hover td {
        background-color: rgba(122, 0, 0, 0.2) !important;
    }
}

.yellow-row {
    background-color: rgba(255, 225, 0, 0.4) !important;
    &:hover td {
        background-color: rgba(255, 255, 0, 0.2) !important;
    }
}

.fw-500 {
    font-weight: 500;
}

.text-center {
    text-align: center !important;
}

.text-danger {
    color: #dc3545;
}

.text-success {
    color: rgb(0, 182, 0);
}

.btn-success {
    background-color: rgb(0, 182, 0);
    border-color: rgb(0, 182, 0);
    color: white;

    &:hover {
        background-color: darken(rgb(0, 182, 0), 10%);
        border-color: darken(rgb(0, 182, 0), 10%);
        color: white;
    }

    &:focus {
        background-color: darken(rgb(0, 182, 0), 10%);
        border-color: darken(rgb(0, 182, 0), 10%);
        color: white;
    }
}

.btn-warning {
    background-color: rgb(234, 104, 20);
    border-color: rgb(234, 104, 20);
    color: white;

    &:hover {
        background-color: darken(rgb(234, 104, 20), 10%);
        border-color: darken(rgb(234, 104, 20), 10%);
        color: white;
    }

    &:focus {
        background-color: darken(rgb(234, 104, 20), 10%);
        border-color: darken(rgb(234, 104, 20), 10%);
        color: white;
    }
}

.border-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 0.5rem;
}

.w-fit-content {
    max-width: fit-content;
}

.h-fit-content {
    max-height: fit-content;
}

.table-container {
    width: 100%;
    overflow-y: auto;
}

.tab-container {
    width: 99% !important; // to fix the horizontal scrollbar visible at all times, when the all content fits into viewport
}

.small-collapse {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 5px 16px !important;
    }
}
